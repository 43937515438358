import Fade from "react-reveal/Fade";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function LatestProjects({ data }) {
  const { header, projects, hyperlinkUrl } = data;
  

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {projects && (
        <>
          <div className="container-fluid block--latest-projects">
            <h2>{header}</h2>
            {projects &&
              projects.map((el, key) => {
                const isEvenRow = key % 2 === 0;
                const imageColumn = (
                  <Fade left>
                  <div className="col-md-8">
                    <img
                      src={el.projectHomepageHeroImage[0].url}
                      alt=""
                      className="img-fluid latest-proj-home-image lp-proj"
                      key={key}
                    />
                  </div>
                  </Fade>
                );
                console.log(hyperlinkUrl);

                const textColumn = (
                  <Fade left>
                  <div className="col-md-4 d-flex align-items-end proj-pads">
                    <div className="inner-wrapper">
                      <h4 className="generic-lineheight">{el.title}</h4>
                      <p className="generic-lineheight">{el.projectName}</p>
                      <Link to={`/projects/${el.slug}`} className="text-decoration-none">
                          <div className="card">
                            <a className="btn-arrow">
                              {hyperlinkUrl && <img src={hyperlinkUrl} className="w-100" alt="" />}
                            </a>

                          </div>
                        </Link>

                    </div>
                  </div>
                  </Fade>
                );
                return (
                  <div className="row my-5" key={key}>
                    {isEvenRow || isMobile ? (
                      <>
                        {imageColumn}
                        {textColumn}
                        
                      </>
                    ) : (
                      <>
                          {textColumn}
                          {imageColumn}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default LatestProjects;
