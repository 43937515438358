function HorizontalDivider({ onGrayBackground }) {
  return (
    <div
      className={`${
        !onGrayBackground ? " " : " gray-bg"
      }`}
    >
      <div className="container">
        <hr />
      </div>
    </div>
  );
}

export default HorizontalDivider;
