import { useEffect, useState } from "react";
import GraphQlHandler from "api/craft/GraphQlHandler";
import { Link } from "react-router-dom";
import appSettings from "../AppSettings";

function MenuFooter() {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			navigationNodes(navHandle: "footerMenu", level: 1) {
			  title
			  url
			  children {
				title
				url
			  }
			}
		  }		  
		`
    ).then((res) => {
      setData({
        ...data,
        menu: res.navigationNodes,
      });
    });
  }, []);

  return (
    <div className="navbar navbar-expand-lg">
      <Link to="/" className="navbar-brand">
        <img src={appSettings.PUBLIC_URL+'logo.svg'} width="100px" alt="logo" />
      </Link>
      <div className="navbar-nav ms-auto">
        {data.menu &&
          data.menu.map((el, idx) => {
            return (
              <a href={el.url} className={`nav-item nav-link`} key={idx}>
                {el.title}
              </a>
            );
          })}
      </div>
    </div>
  );
}

export default MenuFooter;
